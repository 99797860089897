<template>
  <section class="item-types">
    <ul v-if="breadcrumbs" class="breadcrumbs ct mt-3">
        <li class="breadcrumbs__item" @click="goToStep('Location')">
          <a>{{currentPosition.location}}</a>
        </li>
        <li class="breadcrumbs__item" @click="goToStep('Type')">
            <a>{{currentPosition.wdt + 'x' + currentPosition.htl + ' - ' + currentPosition.item.type_name}}</a>
        </li>
        <li class="breadcrumbs__item" @click="goToStep('Style')">
            <a>{{currentPosition.item.style_name}}</a>
        </li>
        <li class="breadcrumbs__item" @click="goToStep('Grade')">
            <a>{{currentPosition.item.grade_name}}</a>
        </li>
    </ul>
    <div class="row" v-if="!package && !set">
      <div class="col-12">
        <div class="divider">
          <div class="divider">
            <span class="divider__text text_green">Default options</span>
          </div>
        </div>
      </div>

      <div class="col-md-2 col-sm-4 col-6">
        <div class="box" @click="toLocation()">
          <p class="box__imgWrap">
            <img class="box__img" src="../../../assets/img/png/icon-set_location.png" alt="Location">
          </p>
          <p class="text_default box__title">Location</p>

        </div>
      </div>

      <div class="col-md-2 col-sm-4 col-6">
        <div class="box" @click="toType()">
          <p class="box__imgWrap">
            <img class="box__img" src="../../../assets/img/png/dimensions.png" alt="Dimensions">
          </p>
          <p class="text_default box__title">Dimensions</p>

        </div>
      </div>

    <div class="col-md-2 col-sm-4 col-6">
        <div class="box" @click="toFrameColour()">
            <div class=" box__imgWrap">
                <div v-if="currentPosition.colour.image" class="h-100 colours colour_box">
                  <img :src="currentPosition.colour.image_url"  class="colour-image" alt="Frame colour"/>
                </div>
                <img v-else class="box__img" src="../../../assets/img/png/colour.png" alt="Colour"/>
            </div>
            <p class="text_default box__title">
                <span>Frame Colour</span>
                <br>
                <span>{{colourName}}</span>
            </p>

        </div>
    </div>

    <div class="col-md-2 col-sm-4 col-6" v-if="currentPosition.has_insert && !currentPosition.has_only_colour">
        <div class="box" @click="toInsertColour()">
          <div class=" box__imgWrap">
            <div v-if="currentPosition.insert_colour.image" class="h-100 colours colour_box">
              <img :src="currentPosition.insert_colour.image_url"  class="colour-image" alt="Insert colour"/>
            </div>
            <img v-else class="box__img" src="../../../assets/img/png/colour.png" alt="Colour"/>
          </div>
            <p class="text_default box__title">
                <span>Insert Colour</span>
                <br>
                <span>{{insertColourName}} <span v-if="currentPosition.insert_colour.is_same_as_frame"> (same as frame)</span> </span>
            </p>

        </div>
    </div>

      <div class="col-md-2 col-sm-4 col-6"
           v-for="(item, index) in options.default"
           :key="item.id"
      >
        <div class="box" @click="toOptions(index, item)"
             :class="{
             'box_opacity' : !item.available,
             'box_incompatible': item.incompatible,
             'box_incomplete': item.is_incomplete,
             disabled: !item.available,
             }">

          <p class="box__imgWrap">
            <img class="box__img" :src="item.icon" :alt="item.name">
          </p>
          <p class="text_default box__title"
             :class="{'text_red': item.is_incomplete}"
          >{{item.name}}</p>
        </div>
      </div>
      <div class="col-12" v-if="options.default.length === 0">
        <p class="text text_green text_italic">No options to configure</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="divider">
          <span class="divider__text text_green">Available options</span>
        </div>
      </div>

      <div class="col-md-2 col-sm-4 col-6"
           v-for="(item, index) in options.available"
           :key="item.id"
      >
        <div class="box" @click="toOptions(index, item)"
             :class="{
             'box_opacity' : !item.available,
             'box_incomplete': item.is_incomplete,
             'box_incompatible': item.incompatible,
             disabled: !item.available,
             }">
          <p class="box__imgWrap">
            <img class="box__img" :src="item.icon" :alt="item.name">
          </p>
          <p class="text_default box__title"
             :class="{'text_red': item.is_incomplete}"
          >{{item.name}}</p>
        </div>
      </div>
      <div class="col-12" v-if="options.available.length === 0">
        <p class="text text_green text_italic">No options to configure</p>
      </div>
    </div>

    <!--<price-options v-if="!package && !set"></price-options>-->

    <div class="row" v-if="incomplete.length > 0">
      <div class="col-12">
        <p class="text_red">Please check options: <span v-for="el in incomplete" :key="el">{{`${el} `}}</span></p>
      </div>
    </div>

    <transition name="fade" v-if="midrail_mullion_reset">
      <div class="modal modal_wrap">
        <div class="modal_popup">
          <p  class="text text_white" v-if="conflict == 'MULL'">
            This item only allows Midrail OR Mullion. Midrails already set up. Adding Mullion will remove Midrails. Do you wish to proceed?
          </p>
          <p  class="text text_white" v-else>
            This item only allows Midrail OR Mullion. Mullions already set up. Adding Midrail will remove Mullions. Do you wish to proceed?
          </p>
          <div class="justify-content-between">
            <button class="btn btn_transparent" @click="proceedToOption">Yes</button>
            <button class="btn btn_transparent" @click="close">No</button>
          </div>
        </div>
      </div>
    </transition>



  </section>
</template>
<script>

  import {defineAsyncComponent} from "vue";

  const PriceOptions = defineAsyncComponent(() => import('./PriceOptions'));
  import {mapState, mapGetters, mapMutations} from 'vuex';

  const breadcrumbs = defineAsyncComponent(() => import("./../../parts/breadcrumbs"));
  export default {
    name: "AllOptions",
    data() {
      return {
        available: [],
        def: [],
        finishPrice: '',
          conflict: '',
          midrail_mullion_reset: false
      }
    },
    props: ['package', 'set', 'editFromSet', 'editFromPackage', 'isEdit'],

    components: {breadcrumbs, PriceOptions},
    // watch: {
    //   'options'() {
    //     this.checkOptions();
    //   },
    // },
    methods: {
      ...mapMutations('orderPosition', ['goToStep']),
      toFrameColour() {
        this.$store.commit('orderPosition/forceStep', {route: {step: 'Frame Colour'}});
      },
      toInsertColour() {
        this.$store.commit('orderPosition/forceStep', {route: {step: 'Insert Colour'}});
      },
      toType() {
        this.$store.commit('orderPosition/forceStep', {route: {step: 'Type'}});
      },

      toLocation() {
          this.$store.commit('orderPosition/forceStep', {route: {step: 'Location'}});
      },

      toOptions(index, item) {

        if (item.code == 'MIDR' || item.code == 'MULL')
        {
            let opposite = (item.code == 'MIDR' ? 'MULL' : 'MIDR'),
                midr = null,
                mull = null

            _.each(this.options.default, (i) =>{
                if (i.code == 'MIDR')
                  midr = i
                else if (i.code == 'MULL')
                    mull = i
            })

            if (midr && mull)
            {
                let count = 0
                _.each(this.options.default[item.code].settings.values, (i) => {
                    if (i.selected)
                        count++
                })

                if (count == 0)
                {
                    this.conflict = item.code
                    this.midrail_mullion_reset = true
                    return
                }
            }

        }

        if (item.available) {
            this.$store.commit('orderPosition/showOption', item.code);
        }
      },

      close(){
          this.midrail_mullion_reset = false;
      },

      proceedToOption(){
          _.each(this.options.default[this.conflict].settings.values, (i) => {
              i.selected = i.required
          })
          _.each(this.options.default[(this.conflict == 'MIDR' ? 'MULL' : 'MIDR')].settings.values, (i) => {
              i.selected = false
          })

          this.$store.commit('orderPosition/setOption', { option: this.conflict, selection: this.options.default[this.conflict].settings})
          this.$store.commit('orderPosition/setOption', { option: (this.conflict == 'MIDR' ? 'MULL' : 'MIDR'), selection: this.options.default[(this.conflict == 'MIDR' ? 'MULL' : 'MIDR')].settings})


          this.$store.commit('orderPosition/showOption', this.conflict);
      } ,

      toAvailable(index, item) {
        if (!item.incompatible) {
          if (item.typeOpt === 'ACC') {
            this.$parent.itemOptions.ACC = true;
            return true;
          }
          else if (this.editFromSet && this.isEdit || this.editFromPackage && this.isEdit) {
            this.$store.dispatch('packages/editSingleOptionInPackage', {
              option: item.typeOpt,
              packageId: this.packageId
            });
          }
          else {
            if (!item.settings && this.package) {
              this.$store.dispatch('packages/getPackageSingleOption', item.typeOpt);
            }
            else if (!item.settings && this.set) {
              this.$store.dispatch('sets/getToSetOption', item.typeOpt);
            }
            else {
              this.$store.commit('options/setAvailableOptions', item);
            }
          }
        }
        this.checkView(item);
      },

      checkView(item) {
        if (item.option_type === 'available') {
          this.$store.commit('options/setModalView', true);
        }
      },

    },

    computed: {
      ...mapState('orderItem', ['isUpdate']),
      ...mapState('options', ['incomplete']),


      ...mapState('packages', ['packageId']),
        ...mapState('orderPosition',['currentPosition']),
        options(){
          return this.$store.getters['orderPosition/orderPosition'].options;
        },

      breadcrumbs() {
        return this.$store.getters['app/get']('breadcrumbs');
      },
      colourName() {
        return _.get(this.currentPosition,'colour.name', '')
      },
      insertColourName() {
        return _.get(this.currentPosition,'insert_colour.name', '')
      },
    },

    created() {
    },

    beforeDestroy() {
    },
  }
</script>

<style scoped lang="scss">
.h-100 {
  height: 100%;
}
.colour-image {
  object-position: center;
  object-fit: cover;
  height: 100%;
}
</style>
